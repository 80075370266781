import React from "react";
import "./App.css";
import logo from "./logo.png";

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="Top">
        <img src={logo} alt="logo" />
      </div>
      <header className="Bottom">
        <div className="Title">
          <h1>Knitify</h1>
        </div>
        <div className="Info">
          <p>Launching summer 2024</p>
          <h1>A small team working on Pikaso</h1>
          <h2>Change any part of any photo</h2>
        </div>
      </header>
      <div className="Footer">
        <p>Copyright © 2024 Knitify Inc. All Rights Reserved</p>
        <p>
          <a id="link" href="mailto:info@knitify.io">Contact Us</a>
        </p>
      </div>
    </div>
  );
};

export default App;
